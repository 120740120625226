import { createStore } from 'vuex'

import { useAuth } from '@/composables/auth'
import i18n from '@/i18n'

const { account } = useAuth()

export default createStore({
  strict: process.env.NODE_ENV !== 'production',
  state: () => ({
    title: 'Anläggning',
    currentStation: null,
    lmBatches: [],
  }),
  getters: {
    loggedIn() {
      return account.value != null
    },
    username() {
      return account.value?.email.toLowerCase()
    },
    formatUsername() {
      return account.value?.name
    },
  },
  mutations: {
    SET_STATE(state, payload) {
      for (const key in payload) if (key in state) state[key] = payload[key]
    },
    SET_TITLE(state, payload) {
      state.title = payload

      const { VITE_ENVIRONMENT_NAME } = import.meta.env
      const defaultTitle = `${i18n.global.t('general.app-name')} ${VITE_ENVIRONMENT_NAME === 'production' ? '' : ` (${VITE_ENVIRONMENT_NAME})`}`
      document.title = payload ? payload + ` | ${defaultTitle}` : defaultTitle
    },
  },
})
