<script lang="ts">
import type { Component } from 'vue'

type EnvName = 'production' | 'test' | 'local'
type FirebaseEnvName = Exclude<EnvName, 'local'>

export interface UiEnvBannerProps {
  frontendEnv: EnvName
  backendEnv: EnvName
  firebaseEnv: FirebaseEnvName
}

interface Entry {
  label: string
  icon: Component
  testId: string
}

interface EntryWithEnv extends Entry {
  env: EnvName
}

interface EnvItem {
  label: string
  entries: Entry[]
  testId: string
}

const TEST_ID_PREFIX = 'env-banner_' as const
export const TEST_ID_PROD_DANGER = `${TEST_ID_PREFIX}prod-danger` as const
export const TEST_ID_UNIFIED = `${TEST_ID_PREFIX}unified-env` as const
export const TEST_ID_SINGLE_LOCAL = `${TEST_ID_PREFIX}single-env-local` as const
export const TEST_ID_SINGLE_TEST = `${TEST_ID_PREFIX}single-env-test` as const
export const TEST_ID_SINGLE_PRODUCTION = `${TEST_ID_PREFIX}single-env-production` as const
export const TEST_ID_FE = `${TEST_ID_PREFIX}entry-fa` as const
export const TEST_ID_BE = `${TEST_ID_PREFIX}entry-be` as const
export const TEST_ID_FB = `${TEST_ID_PREFIX}entry-firebase` as const
</script>

<script setup lang="ts">
import { computed } from 'vue'

import { ComputerDesktopIcon, FireIcon, ServerStackIcon } from '@heroicons/vue/24/outline'
import { omit } from 'lodash'

const COLOR_BY_ENV: Record<string, string> = {
  test: 'bg-blue-400',
  local: 'bg-orange-400',
}

const ENVS_SORTED = [
  { label: 'local', testId: TEST_ID_SINGLE_LOCAL },
  { label: 'test', testId: TEST_ID_SINGLE_TEST },
  { label: 'production', testId: TEST_ID_SINGLE_PRODUCTION },
] as const

const props = defineProps<UiEnvBannerProps>()

const allAreEqual = computed(
  () => props.frontendEnv === props.backendEnv && props.backendEnv === props.firebaseEnv
)

const isDangerouslyUsingProd = computed(
  () =>
    props.frontendEnv !== 'production' &&
    (props.backendEnv === 'production' || props.firebaseEnv === 'production')
)

const entriesWithEnv = computed((): EntryWithEnv[] => [
  { label: 'Frontend', icon: ComputerDesktopIcon, env: props.frontendEnv, testId: TEST_ID_FE },
  { label: 'Backend', icon: ServerStackIcon, env: props.backendEnv, testId: TEST_ID_BE },
  { label: 'Firebase', icon: FireIcon, env: props.firebaseEnv, testId: TEST_ID_FB },
])

const envs = computed(() => {
  if (allAreEqual.value) return []
  const startVal: EnvItem[] = []
  return ENVS_SORTED.reduce((result, { label: envName, testId }) => {
    const entries: Entry[] = entriesWithEnv.value.flatMap(entry =>
      entry.env === envName ? [omit(entry, 'env')] : []
    )
    if (entries.length === 0) return result
    return [...result, { label: envName, entries, testId }]
  }, startVal)
})

const bgColorClass = computed(() => {
  if (isDangerouslyUsingProd.value) return 'bg-red-500'
  return COLOR_BY_ENV[props.frontendEnv]
})
</script>

<template>
  <!--
    Allow click through only on bigger devices, to not block back-btn on mobile.
    We want it clickable on desktop so that the tooltips can show.
  -->
  <div
    v-if="frontendEnv !== 'production'"
    class="pointer-events-none fixed left-0 top-0 z-50 w-64 -translate-x-1/2 -translate-y-1/2 lg:pointer-events-auto"
  >
    <div class="-rotate-45">
      <div class="translate-y-12 border-2 border-white py-1 text-white" :class="bgColorClass">
        <div
          class="border-2 border-dotted border-white py-1 text-center font-bold leading-none text-white"
        >
          <div v-if="allAreEqual" :data-testid="TEST_ID_UNIFIED">
            {{ frontendEnv }}
          </div>

          <div v-else class="flex flex-col gap-1 text-xs">
            <div
              v-for="env in envs"
              :key="env.label"
              class="flex items-center justify-center gap-1"
              :data-testid="env.testId"
            >
              <div class="flex items-center gap-0">
                <div
                  v-for="entry in env.entries"
                  :key="entry.label"
                  class="tooltip tooltip-bottom"
                  :data-testid="entry.testId"
                  :data-tip="entry.label"
                >
                  <component :is="entry.icon" class="w-4" />
                </div>
              </div>

              <p>{{ env.label }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- red frame around viewport to indicate that prod data is being used in a non-prod environment -->
  <div
    v-if="isDangerouslyUsingProd"
    class="pointer-events-none fixed left-0 top-0 z-50 h-full w-full border-4 border-red-500"
    :data-testid="TEST_ID_PROD_DANGER"
  />
</template>
