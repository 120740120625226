import { createRouter, createWebHistory } from 'vue-router'

import NProgress from 'nprogress'

import { useAuth } from '@/composables/auth'

import routes from './routes'

const router = createRouter({
  history: createWebHistory(),
  routes,
})

// Before each route evaluates...
router.beforeEach(async (to, from, next) => {
  if (to.name !== null) {
    NProgress.start()
  }

  const authRequired = to.matched.some(route => route.meta.authRequired)
  await useAuth().initializeAuth({ authRequired })

  next()
})

router.beforeResolve(async (routeTo, routeFrom, next) => {
  // Create a `beforeResolve` hook, which fires whenever
  // `beforeRouteEnter` and `beforeRouteUpdate` would. This
  // allows us to ensure data is fetched even when params change,
  // but the resolved route does not. We put it in `meta` to
  // indicate that it's a hook we created, rather than part of
  // Vue Router (yet?).
  try {
    // For each matched route...
    for (const route of routeTo.matched) {
      await new Promise((resolve, reject) => {
        // If a `beforeResolve` hook is defined, call it with
        // the same arguments as the `beforeEnter` hook.
        if (route.meta && route.meta.beforeResolve) {
          route.meta.beforeResolve(routeTo, routeFrom, (...args) => {
            if (args.length) {
              next(...args)
              reject(new Error('Redirected'))
            } else {
              resolve()
            }
          })
        } else {
          // Otherwise, continue resolving the route.
          resolve()
        }
      })
    }
    // If a `beforeResolve` hook chose to redirect, just return.
  } catch (error) {
    return
  }
  // If we reach this point, continue resolving the route.
  next()
})

// When each route is finished evaluating...
router.afterEach(() => {
  // Complete the animation of the route progress bar.
  NProgress.done()
})

/**
 * Override the router's back function to go to home instead
 * if previous page is not part of the application.
 */
router.back = (function () {
  const originalFn = router.back
  // eslint-disable-next-line func-style
  const newFn = () => {
    // hint for devs to understand we've overridden the function
    // eslint-disable-next-line no-console
    console.log('calling overriden router.back()')
    const prevPath = router.options?.history?.state?.back
    if (!prevPath || (typeof prevPath === 'string' && !prevPath.startsWith('/'))) {
      // previous page not part of application, go to home
      router.replace('/')
      return
    }
    originalFn()
  }
  return newFn
})()

export default router
