import { ref } from 'vue'

import { Auth, type User } from '@/services/auth'

const account = ref<User | null>(null)

async function initializeAuth(options?: Parameters<typeof Auth.initialize>[0]) {
  if (account.value != null) {
    return
  }
  account.value = await Auth.initialize(options)
  return account.value
}

function login(): Promise<void> {
  return Auth.login()
}

async function logout(): Promise<void> {
  account.value = null
  await Auth.logout()
}

export function useAuth() {
  return {
    account,
    initializeAuth,
    login,
    logout,
  }
}
